import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useMemo, useState } from 'react';
import { useTheme } from '@mui/material';
import DatePeriodMenu from '@shared/ui/menus/DatePeriodMenu';
import { TopAssetsChart } from '@entities/wallet';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { BodyMedium } from '@components/styled/Typography';
import TRADING_PAIRS_DATA from './mocks.json';
import TopAssetsTable from './TopAssetsTable';
const Container = styled(ContainerColumn)((props) => ({
    height: 'auto',
    boxSizing: 'border-box',
    boxShadow: `0px 1px 3px 0px ${props.theme.customColors.surface.outline}`,
    backgroundColor: props.theme.customColors.surface.surface,
    borderRadius: 12,
    padding: props.theme.spacing_sizes.l,
    gap: 20,
}));
const TitleContainer = styled(ContainerRow)(() => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const ContentContainer = styled(ContainerRow)(() => ({
    boxSizing: 'border-box',
    paddingLeft: 54,
    alignItems: 'center',
    gap: 100,
}));
const Periods = [
    {
        count: 3,
        type: 'month',
        notation: 'short',
    },
    {
        count: 6,
        type: 'month',
        notation: 'short',
    },
    {
        count: 9,
        type: 'month',
        notation: 'short',
    },
    {
        count: 1,
        type: 'year',
        notation: 'long',
    },
];
const MaxTradingPairsCount = 5;
const toFixed2 = (n) => Math.round((n + Number.EPSILON) * 100) / 100;
const data = TRADING_PAIRS_DATA.sort((a, b) => b.trades_count - a.trades_count);
const TopAssets = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = useMemo(() => [
        theme.customColors.graphs.pie_chart.pie_color.dark_blue,
        theme.customColors.graphs.pie_chart.pie_color.light_blue,
        theme.customColors.graphs.pie_chart.pie_color.dark_green,
        theme.customColors.graphs.pie_chart.pie_color.light_green,
        theme.customColors.graphs.pie_chart.pie_color.yellow,
        theme.customColors.graphs.pie_chart.pie_color.orange,
    ], [theme]);
    const tradingPairs = useMemo(() => {
        const totalTradesCount = data.reduce((prev, curr) => prev + curr.trades_count, 0);
        if (totalTradesCount <= 0) {
            return [];
        }
        const result = [];
        const tradingPairsCount = data.length < MaxTradingPairsCount ?
            data.length :
            MaxTradingPairsCount;
        for (let i = 0; i < tradingPairsCount; i += 1) {
            const tradingPair = data[i];
            result.push({
                name: tradingPair.trading_pair_name,
                percentage: toFixed2((tradingPair.trades_count * 100) / totalTradesCount),
                pnl: tradingPair.pnl,
                tradesCount: tradingPair.trades_count,
            });
        }
        const other = {
            name: t('placeholders.other', { ns: 'common' }),
            percentage: 0,
            pnl: 0,
            tradesCount: 0,
        };
        for (let i = tradingPairsCount - 1; i < data.length; i += 1) {
            const tradingPair = data[i];
            other.percentage += toFixed2((tradingPair.trades_count * 100) / totalTradesCount);
            other.pnl += tradingPair.pnl;
            other.tradesCount += tradingPair.trades_count;
        }
        if (other.tradesCount > 0) {
            result.push(other);
        }
        return result;
    }, [t]);
    const totalTradesDetails = useMemo(() => ({
        pairsCount: data.length,
        tradesCount: data.reduce((tradesCount, currentPair) => tradesCount + currentPair.trades_count, 0),
        totalPnl: data.reduce((totalPnl, currentPair) => totalPnl + currentPair.pnl, 0),
    }), []);
    const tradingPairsColorsMap = useMemo(() => {
        const colorsMap = {};
        tradingPairs.forEach((p, index) => {
            colorsMap[p.name] = colors[index];
        });
        return colorsMap;
    }, [tradingPairs, colors]);
    const [activeTradingPair, setActiveTradingPair] = useState();
    const onGraphArcHover = (tradingPair, isHovered) => {
        if (isHovered) {
            setActiveTradingPair(tradingPair);
        }
        else {
            setActiveTradingPair(undefined);
        }
    };
    const onTableRowHover = (index, tradingPair, isHovered) => {
        if (isHovered) {
            setActiveTradingPair(tradingPair);
        }
        else {
            setActiveTradingPair(undefined);
        }
    };
    const periods = Periods;
    const [currentPeriod, setCurrentPeriod] = useState(periods[0]);
    const onPeriodChange = (p) => {
        setCurrentPeriod(p);
    };
    return (_jsxs(Container, { children: [_jsxs(TitleContainer, { children: [_jsx(BodyMedium, { children: t('portfolio.top_assets') }), _jsx(DatePeriodMenu, { periods: Object.values(periods), currentPeriod: currentPeriod, onPeriodChange: onPeriodChange })] }), _jsxs(ContentContainer, { children: [_jsx(TopAssetsChart, { width: 200, height: 200, data: tradingPairs, colors: tradingPairsColorsMap, activeTradingPair: activeTradingPair, totalTradesDetails: totalTradesDetails, 
                        /*
                        NOTE: placeholder for the empty view
                        data={[
                            {
                                name: 'empty',
                                percentage: 100,
                                pnl: 0,
                                tradesCount: 0,
                            },
                        ]}
                        colors={{ empty: '#EFF1F3' }}
                        totalTradesDetails={{
                            pairsCount: 0,
                            totalPnl: 0,
                            tradesCount: 0,
                        }}
                        */
                        onHover: onGraphArcHover }), _jsx(TopAssetsTable, { data: tradingPairs, colors: tradingPairsColorsMap, onRowHover: onTableRowHover })] })] }));
};
export default TopAssets;
